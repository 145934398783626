import React, { useContext } from "react";
import logoSrc from "../../../../static/2020/svg/logo_2.svg";
import { DataContext } from "../../../contexts/DataContext";
import { StyledFooter } from "../style";

const Footer = () => {
  const { pageLabels } = useContext(DataContext);
  const labels = pageLabels.footer;

  return (
    <StyledFooter>
      <div>
        <a href="/">
          <img src={logoSrc} loading="lazy" />
        </a>
        <h4>{labels.title}</h4>
      </div>
      <p>{labels.address}<br/>{labels.piva}</p>
      <p>{labels.copyright}</p>
    </StyledFooter>
  );
};

export default Footer;
